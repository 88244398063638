export default {
  computed: {
    autocompleteMenuProps () {
      // default properties copied from the vuetify-autocomplete docs
      const defaultProps = {}

      if (this.$vuetify.breakpoint.smAndDown) {
        defaultProps.maxHeight = 150
        defaultProps.closeOnContentClick = true
      } else {
        defaultProps.maxHeight = 250
      }
      return defaultProps
    }
  }
}
